import { createContext } from "react";

export default createContext<{
  update:
    | { workflowRun: WorkflowRun; repository: Repository; workflow: Workflow }
    | undefined;
  setUpdate: (args: {
    workflowRun: WorkflowRun;
    repository: Repository;
    workflow: Workflow;
  }) => void;
}>({
  update: undefined,
  setUpdate: () => undefined,
});
