import { useCallback, useContext, useEffect, useState } from 'react';
import './index.css';
import GithubAppContext from '../../contexts/GithubAppContext';
import WorkflowRun from '../WorkflowRun';
import BranchMatcherContext from '../../contexts/BranchMatcherContext';
import DeploymentContext from '../../contexts/DeploymentContext';
import EnvironmentContext from '../../contexts/EnvironmentContext';
import UpdateContext from '../../contexts/UpdateContext';

export default function Workflow({ workflow, repository, isDev, isProd, branches, tags }: {
  workflow: Workflow,
  repository: Repository,
  isDev: boolean,
  isProd: boolean,
  branches: Array<Branch>,
  tags: Array<Tag>
}) {
  const api = useContext(GithubAppContext)
  const { update } = useContext(UpdateContext)
  const { dev, production, setEnvDeployment } = useContext(DeploymentContext)
  const envs = useContext(EnvironmentContext)
  const [workflowRun, setWorkflowRun] = useState<WorkflowRun>()
  const [deployedWorkflowRun, setDeployedWorkflowRun] = useState<WorkflowRun>()
  const [branch, setBranch] = useState("")
  const [tag, setTag] = useState("")
  const { branch: globalBranch, setBranch: setGlobalBranch } = useContext(BranchMatcherContext)

  useEffect(() => {
    (async () => {
      try {
        setWorkflowRun((await api.request("GET /repos/{owner}/{repo}/actions/workflows/{workflow_id}/runs", {
          owner: 'qispace',
          repo: repository.name,
          workflow_id: workflow.id,
          per_page: 1,
        })).data.workflow_runs[0])
      } catch {
        alert("session expired, reloading")
        localStorage.removeItem("tokens")
        window.location.reload()
      }
    })()
  }, [true])

  useEffect(() => {
    (async () => {
      try {
        setDeployedWorkflowRun((await api.request("GET /repos/{owner}/{repo}/actions/workflows/{workflow_id}/runs", {
          owner: 'qispace',
          repo: repository.name,
          workflow_id: workflow.id,
          status: "success",
          per_page: 1,
        })).data.workflow_runs[0])
      } catch {
        alert("session expired, reloading")
        localStorage.removeItem("tokens")
        window.location.reload()
      }
    })()
  }, [true])

  useEffect(() => {
    if (update && (
      (update.workflow.name.endsWith('dev') && isDev) ||
      (update.workflow.name.endsWith('production') && isProd) ||
      (update.workflow.name.endsWith('staging') && !isDev && !isProd)
    ) && (
        update.repository.name === repository.name
      )) {
      setWorkflowRun(update.workflowRun)
      if (update.workflowRun.conclusion === 'success' && update.workflowRun.status === 'completed') {
        setDeployedWorkflowRun(update.workflowRun)
      }
    }
  }, [update])

  useEffect(() => {
    if (!tag && tags.length > 0 && deployedWorkflowRun && tags[0].name !== deployedWorkflowRun.head_branch) {
      setTag(tags[0].name)
    }
  }, [tag, tags, deployedWorkflowRun])

  useEffect(() => {
    if (isDev) {
      if (branch !== (dev as any)[repository.name].target) {
        setEnvDeployment("dev", repository.name as any, branch)
      }
    }
  }, [branch])

  useEffect(() => {
    if (isProd) {
      if (tag !== (production as any)[repository.name].target) {
        setEnvDeployment("production", repository.name as any, tag)
      }
    }
  }, [tag])

  useEffect(() => {
    if (isDev) {
      if (branch !== (dev as any)[repository.name].target) {
        setBranch((dev as any)[repository.name].target)
      }
    }
  }, [dev])

  useEffect(() => {
    if (isProd) {
      if (tag !== (production as any)[repository.name].target) {
        setTag((production as any)[repository.name].target)
      }
    }
  }, [production])

  useEffect(() => {
    if (isDev && branch !== globalBranch) {
      if (branches.map(b => b.name).includes(globalBranch)) {
        setBranch(globalBranch)
      }
    }
  }, [globalBranch])

  const reRun = useCallback(() => {
    if (workflowRun) {
      if (workflowRun.conclusion === 'failure' || workflowRun.conclusion === 'cancelled') {
        api.request("POST /repos/{owner}/{repo}/actions/runs/{run_id}/rerun", {
          owner: 'qispace',
          repo: repository.name,
          run_id: workflowRun.id
        })
        alert("rerun request has been sent!")
      }
    }
  }, [workflowRun, api, repository])

  const isDeployedNotMostRecent = workflowRun && deployedWorkflowRun && workflowRun.id !== deployedWorkflowRun.id

  return <div className="workflow" style={{ order: isDev ? 3 : isProd ? 2 : 1 }}>
    <div className="workflow-url">
      <a target="_blank" href={envs[isDev ? "dev" : isProd ? "production" : "staging"][repository.name].url}>
        {envs[isDev ? "dev" : isProd ? "production" : "staging"][repository.name].url}
      </a>
    </div>
    <div className="workflow-name">
      {workflow.name}
    </div>
    {isDev && <select value={branch} onChange={e => setBranch(() => {
      setGlobalBranch(e.target.value)
      return e.target.value
    })}>
      <option value="">Select branch to deploy...</option>
      {branches.map(b => <option key={b.name} value={b.name}>{b.name}</option>)}
    </select>}
    {isProd && <select value={tag} onChange={e => setTag(e.target.value)}>
      <option value="">Select tag to deploy...</option>
      {tags.map(t => <option key={t.name} value={t.name}>{t.name}</option>)}
    </select>}
    <div className="workflow-run-overview">
      {(isDeployedNotMostRecent && workflowRun.status !== 'completed') && <span>Deploying <strong>({workflowRun.status})</strong>...:</span>}
      {(isDeployedNotMostRecent && workflowRun.status === 'completed') && <span>Failed <button style={{ padding: 0, lineHeight: '80%' }} onClick={reRun}>rerun</button>:</span>}
      {!isDeployedNotMostRecent && <span>Most recent deployed:</span>}
    </div>
    {workflowRun && <WorkflowRun workflowRun={workflowRun} />}
    {isDeployedNotMostRecent && <div className="workflow-run-overview">
      Most recent deployed:
    </div>}
    {isDeployedNotMostRecent && <WorkflowRun workflowRun={deployedWorkflowRun} />}
  </div>
}