import { useCallback, useContext, useEffect, useState } from "react";
import "./index.css";
import GithubAppContext from "../../contexts/GithubAppContext";
import Repository from "../Repository";
import DeploymentContext from "../../contexts/DeploymentContext";

const QISPACE_REPOS = [
  "qispace-backend",
  "qispace-admin-nuxt",
  "qispace-frontend-nuxt",
  "visuado-admin",
  "visuado-frontend",
];

export default function Repositories() {
  const api = useContext(GithubAppContext);
  const { dev, production, reset } = useContext(DeploymentContext);
  const [repositories, setRepositories] = useState<Array<Repository>>([]);

  useEffect(() => {
    (async () => {
      try {
        for (const repo of QISPACE_REPOS) {
          const fetch = await api.request("GET /repos/{owner}/{repo}", {
            owner: "qispace",
            repo,
          });
          setRepositories((repos) => [...repos, fetch.data]);
        }
      } catch {
        alert("session expired, reloading");
        localStorage.removeItem("tokens");
        window.location.reload();
      }
    })();
  }, [true]);

  const deployProduction = useCallback(() => {
    try {
      for (const [repo, { id, target }] of Object.entries(production)) {
        if (id && target) {
          api.request(
            "POST /repos/{owner}/{repo}/actions/workflows/{workflow_id}/dispatches",
            {
              owner: "qispace",
              repo,
              workflow_id: id,
              ref: target,
            }
          );
        }
      }
      reset();
      alert("production tags deployment request has been sent!");
    } catch {
      alert("session expired, reloading");
      localStorage.removeItem("tokens");
      window.location.reload();
    }
  }, [production]);

  const deployDev = useCallback(() => {
    try {
      for (const [repo, { id, target }] of Object.entries(dev)) {
        if (id && target) {
          api.request(
            "POST /repos/{owner}/{repo}/actions/workflows/{workflow_id}/dispatches",
            {
              owner: "qispace",
              repo,
              workflow_id: id,
              ref: target,
            }
          );
        }
      }
      reset();
      alert("development branches deployment request has been sent!");
    } catch {
      alert("session expired, reloading");
      localStorage.removeItem("tokens");
      window.location.reload();
    }
  }, [dev]);

  const hasProductionDeployments =
    Object.values(production)
      .map((t) => t.target)
      .filter((t) => !!t).length > 0;
  const hasDevDeployments =
    Object.values(dev)
      .map((t) => t.target)
      .filter((t) => !!t).length > 0;

  return (
    <div className="repositories">
      <div className="environments">
        <div className="header">
          <img src="./logo.svg" />
          <div className="sub">Control Room</div>
        </div>
        <div className="environment">
          <div className="title">Staging</div>
          (deployments are controlled via git tags)
        </div>
        <div className="environment">
          <div className="title">Production</div>
          <button
            disabled={!hasProductionDeployments}
            onClick={deployProduction}
          >
            Deploy
          </button>
        </div>
        <div className="environment">
          <div className="title">Dev</div>
          <button disabled={!hasDevDeployments} onClick={deployDev}>
            Deploy
          </button>
        </div>
      </div>
      {repositories.map((repository) => (
        <Repository key={repository.id} repository={repository} />
      ))}
    </div>
  );
}
