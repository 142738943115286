import { Fragment, useCallback, useEffect, useState } from "react";
import { Octokit } from "@octokit/core";
import "./index.css";
import Repositories from "../Repositories";
import GithubUserContext from "../../contexts/GithubUserContext";
import GithubAppContext from "../../contexts/GithubAppContext";
import BranchMatcherContext from "../../contexts/BranchMatcherContext";
import DeploymentContext from "../../contexts/DeploymentContext";
import UpdateContext from "../../contexts/UpdateContext";

export default function App() {
  const [userApi, setUserApi] = useState<Octokit>();
  const [api, setApi] = useState<Octokit>();
  const [branch, setBranch] = useState("");
  const [update, setUpdate] = useState<{
    workflowRun: WorkflowRun;
    repository: Repository;
    workflow: Workflow;
  }>();
  const [envBranches, setEnvBranches] = useState({
    dev: {
      "qispace-backend": { id: -1, target: "" },
      "qispace-frontend-nuxt": { id: -1, target: "" },
      "qispace-admin-nuxt": { id: -1, target: "" },
      "visuado-frontend": { id: -1, target: "" },
      "visuado-admin": { id: -1, target: "" },
    },
    production: {
      "qispace-backend": { id: -1, target: "" },
      "qispace-frontend-nuxt": { id: -1, target: "" },
      "qispace-admin-nuxt": { id: -1, target: "" },
      "visuado-frontend": { id: -1, target: "" },
      "visuado-admin": { id: -1, target: "" },
    },
  });

  useEffect(() => {
    const ws = new WebSocket(process.env.REACT_APP_WS_URL!);
    ws.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if (
        "workflow" in data &&
        "workflow_run" in data &&
        "repository" in data
      ) {
        console.log("update");
        setUpdate({
          repository: data.repository,
          workflow: data.workflow,
          workflowRun: data.workflow_run,
        });
      }
    };
  }, [true]);

  useEffect(() => {
    const tokens = localStorage.getItem("tokens");
    if (tokens) {
      const { userToken, appToken } = JSON.parse(tokens);
      setUserApi(new Octokit({ auth: userToken }));
      setApi(new Octokit({ auth: appToken }));
    }
  }, [true]);

  useEffect(() => {
    (async () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const codeParam = urlParams.get("code");
      if (codeParam) {
        const res = await fetch(
          `${process.env.REACT_APP_PROXY_URL}/user?code=${codeParam}`,
          {
            method: "GET",
          }
        );
        const { userToken, appToken } = await res.json();
        localStorage.setItem("tokens", JSON.stringify({ userToken, appToken }));
        setUserApi(new Octokit({ auth: userToken }));
        setApi(new Octokit({ auth: appToken }));
        window.history.replaceState(null, "", window.location.origin);
      }
    })();
  }, [true]);

  useEffect(() => {
    (async () => {
      if (userApi && api) {
        try {
          await userApi.request("GET /user");
          await api.request("GET /orgs/{org}", {
            org: "qispace",
          });
        } catch {
          localStorage.removeItem("tokens");
          setUserApi(undefined);
          setApi(undefined);
        }
      }
    })();
  }, [userApi]);

  const loginWithGithub = useCallback(() => {
    window.location.assign(
      `https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${window.location.origin}`
    );
  }, [true]);

  return (
    <div className="app">
      {!userApi || !api ? (
        <button
          style={{ width: "100vw", height: "100vh" }}
          onClick={loginWithGithub}
        >
          Login with github
        </button>
      ) : (
        <Fragment>
          <GithubUserContext.Provider value={userApi}>
            <GithubAppContext.Provider value={api}>
              <UpdateContext.Provider value={{ update, setUpdate }}>
                <DeploymentContext.Provider
                  value={{
                    ...envBranches,
                    setEnvDeployment: (
                      env: "dev" | "production",
                      repo:
                        | "qispace-backend"
                        | "qispace-frontend-nuxt"
                        | "qispace-admin-nuxt"
                        | "visuado-frontend"
                        | "visuado-admin",
                      value: string
                    ) => {
                      setEnvBranches((deps) => {
                        const copy = { ...deps };
                        copy[env][repo].target = value;
                        return copy;
                      });
                    },
                    setEnvWorkflowId: (
                      env: "dev" | "production",
                      repo:
                        | "qispace-backend"
                        | "qispace-frontend-nuxt"
                        | "qispace-admin-nuxt"
                        | "visuado-frontend"
                        | "visuado-admin",
                      id: number
                    ) => {
                      setEnvBranches((deps) => {
                        const copy = { ...deps };
                        copy[env][repo].id = id;
                        return copy;
                      });
                    },
                    reset: () => {
                      setEnvBranches((old) => ({
                        dev: {
                          "qispace-backend": {
                            id: old.dev["qispace-backend"].id,
                            target: "",
                          },
                          "qispace-frontend-nuxt": {
                            id: old.dev["qispace-frontend-nuxt"].id,
                            target: "",
                          },
                          "qispace-admin-nuxt": {
                            id: old.dev["qispace-admin-nuxt"].id,
                            target: "",
                          },
                          "visuado-frontend": {
                            id: old.dev["visuado-frontend"].id,
                            target: "",
                          },
                          "visuado-admin": {
                            id: old.dev["visuado-admin"].id,
                            target: "",
                          },
                        },
                        production: {
                          "qispace-backend": {
                            id: old.production["qispace-backend"].id,
                            target: "",
                          },
                          "qispace-frontend-nuxt": {
                            id: old.production["qispace-frontend-nuxt"].id,
                            target: "",
                          },
                          "qispace-admin-nuxt": {
                            id: old.production["qispace-admin-nuxt"].id,
                            target: "",
                          },
                          "visuado-frontend": {
                            id: old.production["visuado-frontend"].id,
                            target: "",
                          },
                          "visuado-admin": {
                            id: old.production["visuado-admin"].id,
                            target: "",
                          },
                        },
                      }));
                    },
                  }}
                >
                  <BranchMatcherContext.Provider value={{ branch, setBranch }}>
                    <Repositories />
                  </BranchMatcherContext.Provider>
                </DeploymentContext.Provider>
              </UpdateContext.Provider>
            </GithubAppContext.Provider>
          </GithubUserContext.Provider>
        </Fragment>
      )}
    </div>
  );
}
