import { useCallback } from 'react';
import './index.css';

export default function Workflow({ workflowRun }: { workflowRun: WorkflowRun }) {
    const renderDate = useCallback(() => {
        if (workflowRun.run_started_at) {
            const date = new Date(workflowRun.run_started_at)
            return `${date.toLocaleString()}`
        } else {
            return "-"
        }
    }, [workflowRun.run_started_at])
    return <div className="workflow-run">
        <div className="version">{workflowRun.head_branch}</div>
        <div className={`status ${workflowRun.conclusion || workflowRun.status}`}><div className="icon" /><u>{workflowRun.conclusion || workflowRun.status}</u>&nbsp;on&nbsp;{renderDate()}</div>
        <div className="at"><a target="_blank" href={workflowRun.html_url}>see details</a></div>
    </div>
}