import { createContext } from "react";

export default createContext<{
  dev: {
    "qispace-backend": { id: number; target: string };
    "qispace-frontend-nuxt": { id: number; target: string };
    "qispace-admin-nuxt": { id: number; target: string };
    "visuado-frontend": { id: number; target: string };
    "visuado-admin": { id: number; target: string };
  };
  production: {
    "qispace-backend": { id: number; target: string };
    "qispace-frontend-nuxt": { id: number; target: string };
    "qispace-admin-nuxt": { id: number; target: string };
    "visuado-frontend": { id: number; target: string };
    "visuado-admin": { id: number; target: string };
  };
  setEnvDeployment: (
    env: "dev" | "production",
    repo:
      | "qispace-backend"
      | "qispace-frontend-nuxt"
      | "qispace-admin-nuxt"
      | "visuado-frontend"
      | "visuado-admin",
    value: string
  ) => void;
  setEnvWorkflowId: (
    env: "dev" | "production",
    repo:
      | "qispace-backend"
      | "qispace-frontend-nuxt"
      | "qispace-admin-nuxt"
      | "visuado-frontend"
      | "visuado-admin",
    id: number
  ) => void;
  reset: () => void;
}>({
  dev: {
    "qispace-backend": { id: -1, target: "" },
    "qispace-frontend-nuxt": { id: -1, target: "" },
    "qispace-admin-nuxt": { id: -1, target: "" },
    "visuado-frontend": { id: -1, target: "" },
    "visuado-admin": { id: -1, target: "" },
  },
  production: {
    "qispace-backend": { id: -1, target: "" },
    "qispace-frontend-nuxt": { id: -1, target: "" },
    "qispace-admin-nuxt": { id: -1, target: "" },
    "visuado-frontend": { id: -1, target: "" },
    "visuado-admin": { id: -1, target: "" },
  },
  setEnvDeployment: () => undefined,
  setEnvWorkflowId: () => undefined,
  reset: () => undefined,
});
