import { useContext, useEffect, useState } from 'react';
import './index.css';
import GithubAppContext from '../../contexts/GithubAppContext';
import Workflow from '../Workflow';
import DeploymentContext from '../../contexts/DeploymentContext';

export default function Repository({ repository }: { repository: Repository }) {
  const api = useContext(GithubAppContext)
  const { setEnvWorkflowId } = useContext(DeploymentContext)
  const [workflows, setWorkflows] = useState<Array<Workflow>>([])
  const [branches, setBranches] = useState<Array<Branch>>([])
  const [tags, setTags] = useState<Array<Tag>>([])

  useEffect(() => {
    (async () => {
      try {
        setWorkflows((await api.request("GET /repos/{owner}/{repo}/actions/workflows", {
          owner: 'qispace',
          repo: repository.name
        })).data.workflows)
      } catch {
        alert("session expired, reloading")
        localStorage.removeItem("tokens")
        window.location.reload()
      }
    })()
  }, [true])

  useEffect(() => {
    (async () => {
      try {
        setBranches((await api.request("GET /repos/{owner}/{repo}/branches", {
          owner: 'qispace',
          repo: repository.name,
          per_page: 10000
        })).data)
      } catch {
        alert("session expired, reloading")
        localStorage.removeItem("tokens")
        window.location.reload()
      }
    })()
  }, [true])

  useEffect(() => {
    (async () => {
      try {
        setTags((await api.request("GET /repos/{owner}/{repo}/tags", {
          owner: 'qispace',
          repo: repository.name,
          per_page: 10000
        })).data)
      } catch {
        alert("session expired, reloading")
        localStorage.removeItem("tokens")
        window.location.reload()
      }
    })()
  }, [true])

  useEffect(() => {
    for (const workflow of workflows) {
      if (workflow.name.endsWith("dev")) {
        setEnvWorkflowId("dev", repository.name as any, workflow.id)
      } else if (workflow.name.endsWith("production")) {
        setEnvWorkflowId("production", repository.name as any, workflow.id)
      }
    }
  }, [workflows])

  return <div className="workflows">
    <div className="repository">
      <div className="title">{repository.name}</div>
      <a target="_blank" href={repository.html_url}>{repository.html_url}</a>
    </div>
    {workflows.map(workflow => <Workflow key={workflow.id} workflow={workflow} repository={repository} isDev={workflow.name.endsWith("dev")} isProd={workflow.name.endsWith("production")} branches={branches} tags={tags} />)}
  </div>
}
