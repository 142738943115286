import { createContext } from "react";

export default createContext<{ [key: string]: any }>({
  production: {
    "qispace-backend": {
      url: "https://api.qispace.com",
    },
    "qispace-frontend-nuxt": {
      url: "https://flow.qispace.com/smietangen",
    },
    "qispace-admin-nuxt": {
      url: "https://admin.qispace.com",
    },
    "visuado-frontend": {
      url: "https://live.visuado.com/smietangen",
    },
    "visuado-admin": {
      url: "https://admin-beta.visuado.com",
    },
  },
  staging: {
    "qispace-backend": {
      url: "https://api.qistage.com",
    },
    "qispace-frontend-nuxt": {
      url: "https://qistage.com/smietangen",
    },
    "qispace-admin-nuxt": {
      url: "https://admin.qistage.com",
    },
    "visuado-frontend": {
      url: "https://frontend-beta.qistage.com/smietangen",
    },
    "visuado-admin": {
      url: "https://admin-beta.qistage.com",
    },
  },
  dev: {
    "qispace-backend": {
      url: "https://dev-api.qistage.com",
    },
    "qispace-frontend-nuxt": {
      url: "https://dev.qistage.com/smietangen",
    },
    "qispace-admin-nuxt": {
      url: "https://dev-admin.qistage.com",
    },
    "visuado-frontend": {
      url: "https://frontend-beta.qistage.com/smietangen",
    },
    "visuado-admin": {
      url: "https://admin-beta.qistage.com",
    },
  },
});
